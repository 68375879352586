import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import BlogWrapper from "../../../components/article/BlogWrapper";
import { Link } from "gatsby";

const SEO = {
  MetaTitle: "Choosing a Location for Your Business",
  MetaDescription:
    "Are you starting a small business? Find out top tips on how to choose the best location for your business from the small business team at WaFd Bank.",
  Schema: {
    "@id": "https://www.wafdbank.com/blog/small-business/choosing-location-for-business",
    "@type": "Article",
    image: "https://www.wafdbank.com/images/adsearch/og-choosing-location-for-business-083123.jpg",
    "@context": "https://schema.org",
    headline: "Choosing a Location for Your Business",
    description:
      "Are you starting a small business? Find out top tips on how to choose the best location for your business from the small business team at WaFd Bank."
  }
};

const ChoosingLocationForBusinessBlog = () => {
  const cards = [
    "/blog/small-business/small-business-cybersecurity-tips",
    "/blog/small-business/how-to-move-a-business-to-another-state",
    "/blog/small-business/what-is-the-best-bank-for-a-small-business"
  ];

  return (
    <BlogWrapper
      title={"Factors to Consider When Finding the Right Location for Your Business"}
      pageUrl={"/blog/small-business/choosing-location-for-business"}
      ogImage={"https://www.wafdbank.com/images/adsearch/og-choosing-location-for-business-083123.jpg"}
      contactInfo={"LocalBranch"}
      seoData={SEO}
      cards={cards}
    >
      <>
        <h4>
          While many small businesses start out of an owner's garage, there often comes a time when the inventory,
          orders, or the number of employees gets to be too much for an in-home operation. If you're thinking about
          moving your business to a storefront or warehouse, then there's a lot to think about when choosing a location.
        </h4>
        <StaticImage
          src="../../../images/blogs/small-business/choosing-location-for-business/blog-right-business-location-730.jpg"
          alt={`Happy shop owner opening doors at his cafe.`}
          className="my-4"
          placeholder="blurred"
          quality="100"
        />

        <h2>Consider the Financials</h2>
        <p>
          First things first -it's important to be realistic about what you can afford to rent (or buy.) Regardless of
          where you're looking to settle down, you'll likely need to make changes or improvements to the space. You'll
          also want to consider the income, sales and property taxes of the state and county you're considering. Another
          tip, some businesses qualify for government economic business programs, such as state-specific small business
          loans or micro-loans. These vary greatly by state, so you'll want to check with your state's department of
          revenue.
        </p>

        <h2>Is the Area Business Friendly?</h2>
        <p>
          Because business laws are drastically different throughout the country, and often even within the same state,
          be sure to check what applies in your area. Spending a little more on a location one county away could save
          you a lot in taxes. If you're new to an area or just starting out, you may want to consider checking with an
          expert. Your local{" "}
          <a href="https://www.sba.gov/" target="_blank" rel="noopener noreferrer">
            Small Business Development Center
          </a>{" "}
          should be able to point you in the right direction.
        </p>

        <h2>Hiring the Right Team</h2>
        <p>
          Take a look at minimum wage; some states have a much higher minimum wage than the current federal rate of
          $7.25.{" "}
          <a href="https://www.dol.gov/whd/minwage/america.htm" target="_blank" rel="noopener noreferrer">
            The Department of Labor offers a valuable website
          </a>{" "}
          to help you quickly and easily find out.
        </p>
        <p>
          You'll also want to consider the available talent pool in the area, especially if you need highly or specially
          skilled workers, like information technology or medicine.
        </p>

        <h2>Types of Business Locations</h2>
        <p>
          Depending on your industry, you could have a few different choices. You might be able to run your business out
          of your home, or you may have to find a location in an urban area, perhaps near a mall or other popular
          community space. If you distribute goods, your best bet might be to go for a more industrial area where it's
          easier for delivery trucks to navigate. This will help you narrow your search significantly as you consider
          the logistics of how you'll operate.
        </p>
        <p>
          If you can, consider testing out an area and do a pop up instead. This is a temporary location where you can
          get to know the local preferences and what they might be willing to pay. Try out a few different locations and
          look at all the factors. How much did you make per day on average? What kind of feedback did you get from your
          customers? Then you can make an informed decision and choose the location where your business did best.
        </p>

        <h2>Check out the Neighbors</h2>
        <p>
          This is key. What is the market's saturation of your industry? Research and get to know your competitors. Also
          think about any businesses or vendors that supply you with crucial items. How efficiently can they get to you?
          (And will it cost them any more money to do so?) If you're a retail store or deal heavily with cash, then you
          may also want to look at the crime rates in the area.
        </p>
        <p>
          By doing just a little research, you'll end up saving yourself a lot of headache down the road! If you're
          looking for more advice, then be sure to check out the{" "}
          <a
            href="https://www.sba.gov/about-sba/sba-locations/headquarters-offices/office-small-business-development-centers/office-small-business-development-centers-resources"
            target="_blank"
            rel="noopener noreferrer"
          >
            US Small Business Association's "Starting & Managing a Business" website
          </a>
          . Additionally,{" "}
          <a
            href="https://www.thebalance.com/choosing-a-location-for-your-business-1201032"
            target="_blank"
            rel="noopener noreferrer"
          >
            TheBalance.com
          </a>{" "}
          also offers some helpful advice for choosing a location.
        </p>

        <h2>WaFd Bank is Here to Help</h2>
        <p>
          Whether your small business is looking for a better way to pay employees, deposit checks, or finance an
          expansion, our local small business bankers have the community knowledge and business experience to help. Find
          your{" "}
          <Link to="/locations" id="internal-page-link-locations">
            local WaFd Bank branch
          </Link>{" "}
          to learn more, or open a{" "}
          <Link
            to="/business-banking/open-business-bank-account-online"
            id="internal-page-link-open-business-bank-account-online"
          >
            business bank account
          </Link>{" "}
          online today.
        </p>
      </>
    </BlogWrapper>
  );
};

export default ChoosingLocationForBusinessBlog;
